import React from "react"
import { Container, Row, Col, CardDeck, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faComment } from "@fortawesome/pro-light-svg-icons"

const RealPeople = () => (
    <Container fluid 
        style={{
            backgroundColor: '#3B5272'
        }}>
    <Row>
        <Col>
        <Container className="big-y-margin align-vertical">
            <Row>
            <Col>
             <h1 className="uniqueCare mb-4" style={{
            textAlign: 'center',
            color: 'white'
        }}>Real People, Real Lives, Quality of Life Gained</h1>
             </Col>
            </Row>
            <Row className="big-y-margin">
            <CardDeck>
                <Card className="homeCard" style={{
                    marginBottom: '3rem'
                }}>
                    <Card.Body>
                        <div className="text-center commentContainer">
                    <FontAwesomeIcon 
                        icon={faComment}
                        className="comment"
                        aria-hidden="true"
                    />
                    </div>

                        <Card.Text className="commentCardText">
                        Symphony has made coordinating my father’s care so simple and streamlined. I’m able to see everything in one place and help him make decisions about daily life and medical treatment.
                    </Card.Text>
                    <Card.Text className="commentCardName">
                    John Samuelson
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="homeCard" style={{
                    marginBottom: '3rem'
                }}>
                    <Card.Body>
                        <div className="text-center commentContainer">
                    <FontAwesomeIcon 
                        icon={faComment}
                        className="comment"
                        aria-hidden="true"
                    />
                    </div>

                        <Card.Text className="commentCardText">
                        The ability to see the entire picture of my client’s needs is incredibly helpful. I’m able to communicate with other advisors, my client and his family.
                    </Card.Text>
                    <Card.Text className="commentCardName">
                    Steve Carson, Financial Advisor
                    </Card.Text>
                    </Card.Body>
                </Card><Card className="homeCard" style={{
                    marginBottom: '3rem'
                }}>
                    <Card.Body>
                        <div className="text-center commentContainer">
                    <FontAwesomeIcon 
                        icon={faComment}
                        className="comment"
                        aria-hidden="true"
                    />
                    </div>

                        <Card.Text className="commentCardText">
                        There were so many parts of the puzzle we were trying to fit together when juggling mom’s care at home. With Symphony, we are able to bring it all together and communicate easily with all the important team members it takes to care for her.
                    </Card.Text>
                    <Card.Text className="commentCardName">
                    Karen Cozgrove
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            </Row>
            </Container>
        </Col>
    </Row>

    </Container>
)

export default RealPeople