import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styles from "./Homepage.module.css"

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

        const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
const CommunicationPlatform = (props) => {
    const data = useStaticQuery(graphql`
query {
    inHome: file(relativePath: { eq: "partner-logos/In-Home-Care-Alliance-Arizona-Home-Care-logo.png" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    drPlus: file(relativePath: { eq: "partner-logos/dr-plus-logo.png" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    bbvaCompass: file(relativePath: { eq: "partner-logos/bbva-compass-logo.png" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    firstLight: file(relativePath: { eq: "partner-logos/first-light-logo.png" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    barrow: file(relativePath: { eq: "partner-logos/barrow-logo.png" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    essential: file(relativePath: { eq: "partner-logos/essential-logo.png" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    homeCare: file(relativePath: { eq: "partner-logos/home-care-logo.jpg" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    hospiceValley: file(relativePath: { eq: "partner-logos/Hospice-of-the-Valley-logo.jpg" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    ris: file(relativePath: { eq: "partner-logos/RIS-Logo.jpg" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }
    valleySun: file(relativePath: { eq: "partner-logos/Valley-of-the-Sun-Logo.jpg" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }  
    alca: file(relativePath: { eq: "partner-logos/alca-logo.jpg" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }    
    nightingale: file(relativePath: { eq: "partner-logos/NightingaleHomecareLogo.jpg" }) {
        childImageSharp {
            fixed(width: 160, quality: 80) {
                ...GatsbyImageSharpFixed
            }
        }
    }    
}`) 
return (
        <Container className="big-y-margin">
            <Row className="h-100">
                <Col className="my-auto" >
                    <Row>
                        <Col>
                        <h1 className={`${styles.uniqueCare} mb-4`} style={{
                textAlign: 'center'
            }}>
                Our Partners Orchestrating Quality Care
            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        deviceType={props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        renderButtonGroupOutside={true} 
        renderDotsOutside={true}
        
        partialVisible={true} 
        arrows={false} 
      > 
{/* <div className={styles.partnerLogo}><Img fixed={data.inHome.childImageSharp.fixed} alt="In-Home Care Alliance logo" width="160" height="132"/></div> */}
{/* <div className={styles.partnerLogo}><Img fixed={data.drPlus.childImageSharp.fixed} alt="Dr. + logo" width="190" height="1" /></div> */}
<div className={styles.partnerLogo}><Img fixed={data.nightingale.childImageSharp.fixed} alt="Nightingale Homecare logo" width="160" height="132"/></div>
<div className={styles.partnerLogo}><Img fixed={data.firstLight.childImageSharp.fixed} alt="First Light logo" /></div>
<div className={styles.partnerLogo}><Img fixed={data.bbvaCompass.childImageSharp.fixed} alt="BBVA Compass logo"  /></div>
<div className={styles.partnerLogo}><Img fixed={data.essential.childImageSharp.fixed} alt="Essential logo" /></div> 
<div className={styles.partnerLogo}><Img fixed={data.barrow.childImageSharp.fixed} alt="Barrow logo" /></div>
<div className={styles.partnerLogo}><Img fixed={data.homeCare.childImageSharp.fixed} alt="Home Care logo" /></div> 
<div className={styles.partnerLogo}><Img fixed={data.hospiceValley.childImageSharp.fixed} alt="Hospice of the Valley logo" /></div>
<div className={styles.partnerLogo}><Img fixed={data.ris.childImageSharp.fixed} alt="RIS logo" /></div>
<div className={styles.partnerLogo}><Img fixed={data.valleySun.childImageSharp.fixed} alt="Valley of the Sun logo" /></div>
<div className={styles.partnerLogo}><Img fixed={data.alca.childImageSharp.fixed} alt="Alca Logo" /></div>
                                </Carousel>

                                

                        </Col>
                    </Row>

                    {/* <Row>
                        <Col sm={2} className="text-center my-4">
                            <Img fixed={data.inHome.childImageSharp.fixed} alt="In-Home Care Alliance logo" width="160" height="132" />
                        </Col>
                        <Col sm={2} className="text-center my-4">
                            <Img fixed={data.drPlus.childImageSharp.fixed} alt="Dr. + logo" width="190" height="1" />
                        </Col>
                        <Col sm={2} className="text-center my-4">
                            <Img fixed={data.bbvaCompass.childImageSharp.fixed} alt="BBVA Compass logo" />
                        </Col>
                        <Col sm={2} className="text-center my-4">
                            <Img fixed={data.firstLight.childImageSharp.fixed} alt="First Light logo" />
                        </Col>
                        <Col sm={2} className="text-center my-4">
                            <Img fixed={data.barrow.childImageSharp.fixed} alt="Barrow logo" />
                        </Col>
                        <Col sm={2} className="text-center my-4">
                            <Img fixed={data.essential.childImageSharp.fixed} alt="Essential logo" />
                        </Col>
                    </Row> */}
    
                </Col>
            </Row>
        </Container>
)
}

export default CommunicationPlatform